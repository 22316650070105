
@font-face {
    font-family: "Times New Roman";
    src: url('../font-icon/Times_New_Roman/times.ttf')
         url('../font-icon/Times_New_Roman/timesbd.ttf')
         url('../font-icon/Times_New_Roman/timesbi.ttf')
         url('../font-icon/Times_New_Roman/timesi.ttf');
}
@font-face {
    font-family: "Inter";
    src: url('../font-icon/Inter/Inter-Black.ttf')
         url('../font-icon/Inter/Inter-Bold.ttf')
         url('../font-icon/Inter//Inter-ExtraBold.ttf')
         url('../font-icon/Inter/Inter-ExtraLight.ttf')
         url('../font-icon/Inter/Inter-Light.ttf')
         url('../font-icon/Inter/Inter-Medium.ttf')
         url('../font-icon/Inter/Inter-Regular.ttf')
         url('../font-icon/Inter/Inter-SemiBold.ttf')
         url('../font-icon/Inter/Inter-Thin.ttf');
}
@font-face {
    font-family: "Courier New";
    src: url('../font-icon/Courier_New/cour.ttf')
         url('../font-icon/Courier_New/courbd.ttf')
         url('../font-icon/Courier_New/courbi.ttf')
         url('../font-icon/Courier_New/couri.ttf');
}
*{
    padding: 0;
    margin: 0;
    list-style: none;
    // font-family: "Times New Roman" !important;
}
.mb-100 {
    margin-bottom: 100px;
}
.sf-wrapper {
    background: #161718;
}
.sf-wrapper-white {
    background-color: #fff;
    // overflow: hidden;
}
.sf-content-wrap {
    background: #161718;
}
/* 修改垂直滚动条 */
::-webkit-scrollbar {
    width: 0px; /* 修改宽度 */
}

/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
    background-color: #202020;
    display: none;
}

/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
    background-color: #000;
    display: none;
}

/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
    background-color: #000;
}

/* 修改滚动条滑块移动时的颜色 */
::-webkit-scrollbar-thumb:active {
    background-color: #000;
}

/* 修改滚动条滑块的圆角 */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    display: none;
}

.d-f {
    display: flex;
}
.f-1 {
    flex: 1;
}
.pt-100 {
    padding-top: 100px;
}
.mb-60 {
    margin-bottom: 60px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-20 {
    margin-bottom: 20px;
}
.w-720 {
    width: 720px;
}
.m-0-a {
    margin: 0 auto;
}
.f-1 {
    flex: 1;
}
.j-c-s-b {
    justify-content: space-between;
}
.a-i-c {
    align-items: center;
}
@media screen and (min-width: 1280px) {
    .sf-big-img-wrap {
        width: 1180px;
        overflow: scroll;
        height: calc(100vh - 200px);
        &::-webkit-scrollbar:horizontal {
            width: 10px;
            height: 4px; /* 滚动条高度 */
            // background-color: #000; /* 滚动条背景色 */
          }
           
          /* 自定义滚动条滑块的样式 */
          &::-webkit-scrollbar-thumb {
            border-radius: 10px; /* 滑块圆角 */
            background-color: rgba(217, 217, 217, 1); /* 滑块颜色 */
            display: block;
          }
    }
    .model-banner-box {
        width: 100%;
        height: 500px;
        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
        font-size: 64px;
        font-weight: 700;
        line-height: 78px;
        text-align: left;
        color: #fff;
        position: relative;
    }
    .mode-qualitySequence-wrap {
        .qualitySequence-banner-img{
            background: url('../img/gackground/4.jpg') no-repeat center / cover;
            .qualitySequence-banner-title {
                white-space: nowrap;
                position: absolute;
                bottom: 163px;
                left: 50%;
                transform: translateX(-50%);
                li {
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    &:first-child {
                        margin-right: 108px;
                    }
                    &:last-child {
                        margin-left: 194px;
                    }
                }
            }
        }
        .prodClusters-banner-img{
            background: url('../img/gackground/7.jpg') no-repeat center / cover;
            .prodClusters-banner-title {
                white-space: nowrap;
                position: absolute;
                bottom: 153px;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                text-transform: capitalize;
                li {
                    letter-spacing: 1px;
                    &:first-child {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 42px;
                        font-weight: 700;
                        line-height: 51px;
                        text-align: center;
                        margin-bottom: 30px;
                    }
                    &:last-child {
                        text-align-last: justify;
                        font-size: 72px;
                    }
                }
            }
        }
        .content-first-model {
            .q_c_f_w_b {
                width: calc(100% - 200px);
                height: 360px;
                border-radius: 20px;
                background: rgba(34, 34, 34, 1);
                padding: 60px 100px;
                color: #fff;
                .q_c_f_w_b-title {
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 37px;
                    margin-bottom: 24px;
                    text-transform: capitalize;
                    letter-spacing: 0.6px;
                }
                .q_c_f_w_b-content {
                    font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 36px;
                    text-align: left;
                    margin-bottom: 48px;
                    letter-spacing: 0.6px;
                }
                .diver {
                    margin-bottom: 42px;
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: left;
                    align-items: center;
                    text-transform: capitalize;
                    span {
                        color: rgba(47, 239, 119, 1);
                    }
                    > div {
                        margin-left: 22px;
                        border-top: 1px dashed rgba(51, 51, 51, 1)
                    }
                }
                
                .q_c_f_w_b-list {
                    flex: 1;
                    li {
                        width: 50%;
                        float: left;
                        line-height: 36px;
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: left;
                        margin-bottom: 24px;
                        letter-spacing: 0.4px;

                        span {
                            font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 30px;
                            font-weight: 700;
                            color: rgba(47, 239, 119, 1);
                            margin-right: 10px;
                            position: relative;
                            top: 4px;
                        }
                    }
                }
                .q_c_f_w_b-button {
                    width: 365px;
                    height: 101px;
                    border-radius: 6px;
                    background: rgba(124, 58, 237, 1);
                    margin-left: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    line-height: 36px;
                    user-select: none;
                    cursor: pointer;
                    .q_c_f_w_b-button-big {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 24px;
                        font-weight: 700;
                        text-align: left;
                    }
                    .q_c_f_w_b-button-small {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0.05em;
                    }
                }
            }
        }
        .batch-model-box {
            .model-content-title {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 34px;
                font-weight: 700;
                line-height: 44px;
                margin-bottom: 36px;
                text-transform: capitalize;
                letter-spacing: 0.6px;
            }
            .model-content-img-left {
                width: 580px;
                height: 420px;
                border-radius: 20px;
                margin-right: 61px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            
            .model-content-img-right {
                width: 580px;
                height: 420px;
                border-radius: 20px;
                margin-left: 61px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .model-content-content-article {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 36px;
                text-align: left;
                margin-bottom: 36px;
                letter-spacing: 0.6px;
            }
            .model-content-content-button {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                text-decoration: underline;
                user-select: none;
                cursor: pointer;
                letter-spacing: 0.6px;
            }
        }
        .model-foot-content {
            width: 1280px;
            height: 880px;
            border-radius: 20px;
            .model-foot-content-box {
                width: 1280px;
                height: 720px;
            }
            .slick-slider {
                position: relative;
                .slick-dots-bottom {
                    width: 1280px;
                    position: absolute;
                    bottom: -70px;
                }
                .slick-list {
                    border-radius: 20px;
                }
            }
            // img {
            //     width: 1280px;
            //     height: 720px;
            // }
        }
        .qualitySequence-bottom-slideShow {
            padding-top: 80px;
            font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
            font-size: 22px;
            font-weight: 400;
            line-height: 40px;
            text-align: left;
            color: #fff;letter-spacing: 0.6px;
            p {
                margin-bottom: 60px;
                &.qualitySequence-bottom-article1 {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .model-content-box {
        width: 1280px;
        margin: 0 auto;
        ul {
            li.mode-box {
                padding: 100px 0;
                border-bottom: 1px dashed rgba(51, 51, 51, 1);
                color: #fff;
            }
        }
    }
    .sf-custom-footer-wrap {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #060708;
        background-color: #eee;
        > div {
            text-align: center;
            .sf-custom-footer-describute {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 36px;
                text-align: center;
                padding-top: 24px;
            }
        }
    }
    .sf-detail-header {
        height: 720px;
        background: rgba(22, 23, 24, 1);
        position: relative;
        .d_h_c_b {
            width: 1280px;
            position: absolute;
            bottom: 135px;
            left: 50%;
            transform: translateX(-50%);
            .d_h_c_b_l {
                width: 770px;
                height: 450px;
                margin-right: 60px;
                color: #fff;
                position: relative;
                .d_h_c_b_l_title {
                    width: fit-content;
                    margin-bottom: 64px;
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 60px;
                    font-weight: 700;
                    line-height: 86px;
                    position: absolute;
                    letter-spacing: 1px;
                    z-index: 999;
                    padding-top: 28px;
                    p {
                        float: left;
                    }
                    .t_t_l {
                        width: fit-content;
                        height: auto;
                        white-space: nowrap;
                        overflow: hidden;
                        display: flex;
                        p {
                            margin-right: 24px;
                        }
                    }
                    .t_t_r {
                        width: fit-content;
                        height: auto;
                        white-space: nowrap;
                        overflow: hidden;
                        display: flex;
                        p {
                            margin-left: 24px;
                        }
                        .b_d_b {
                            .b_d_b_t {
                                width: 240px;
                            }
                            .b_d_b_b {
                                width: 180px;
                            }
                        }
                    }
                    .b_d_b {
                        float: left;
                        padding-top: 24px;
                        .b_d_b_t {
                            width: 260px;
                            height: 16px;
                            border-radius: 4px;
                            margin-bottom: 12px;
                            background: linear-gradient(90deg, #333333 0%, #444444 100%);
                        }
                        .b_d_b_b {
                            width: 200px;
                            height: 16px;
                            border-radius: 4px;
                            background: linear-gradient(90deg, #333333 0%, #444444 100%);
    
                        }
                    }
                }
                p.d_h_c_b_l_content {
                    width: 770px;
                    font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 40px;
                    color:rgba(204, 204, 204, 1);
                    position: absolute;
                    bottom: 4px;
                    line-height: 40px;
                }
            }
            .d_h_c_b_r {
                width: 450px;
                height: 450px;
                background: url('../img/gackground/11.jpg') no-repeat center / cover;
                position: relative;
                .d_h_c_b_r_box {
                    position: absolute;
                    bottom: 57px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    mix-blend-mode: difference;
                    .d_h_c_b_r_t_1{
                        font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 54px;
                        font-weight: 700;
                        line-height: 58px;
                        text-align: center;
                    }
                    .d_h_c_b_r_t_2 {
                        margin-bottom: 40px;
                    }
                    .d_h_c_b_r_t_3 {
                        font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 13px;
                        text-align: center;
    
                    }
                }
            }
        }
    }
    .sf-detail-content {
        background: #fff;
        padding-top: 80px;
        padding-bottom: 173px;
        .sf-detail-content-box {
            width: 1280px;
            margin: 0 auto;
            .d_c_t_1 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 50px;
                font-weight: 700;
                line-height: 72px;
                text-transform: capitalize;
                letter-spacing: 0.6px;
            }
            .d_c_t_s {
                font-family: "Times New Roman", "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                margin-bottom: 60px;
            }
            .d_c_i {
                font-family: "Times New Roman", "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-style: italic;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0.6px;
            }
            .d_model_2 {
                padding-top: 60px;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 60px;
                border-bottom: 1px dashed rgba(51, 51, 51, 1);
                .d_model_2_item {
                    width: calc(((100% - 80px) / 3) - 62px);
                    margin-right: 40px;
                    padding: 35px 30px;
                    border: 1px dashed rgba(102, 102, 102, 1);
                    background: rgba(238, 238, 238, 1);
                    margin-bottom: 40px;
                    border-radius: 18px;
                    .d_model_2_item_title {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 30px;
                        color: rgba(22, 23, 24, 1);
                        margin-bottom: 31px;letter-spacing: 0.6px;
                    }
                    .d_model_2_item_c {
                        font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                        color: rgba(22, 23, 24, 1);letter-spacing: 0.6px;
                        cursor: pointer;
                    }
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }
            .d_m_3 {
                color: rgba(22, 23, 24, 1);
                .d_m_3_lunbo_item_img {
                        &::-webkit-scrollbar:horizontal {
                        width: 10px;
                        height: 4px; /* 滚动条高度 */
                        // background-color: #000; /* 滚动条背景色 */
                      }
                       
                      /* 自定义滚动条滑块的样式 */
                      &::-webkit-scrollbar-thumb {
                        border-radius: 10px; /* 滑块圆角 */
                        background-color: rgba(217, 217, 217, 1); /* 滑块颜色 */
                        display: block;
                      }
                }
                .d_m_3_title {
                    font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-style: normal;
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 36px;
                    margin-bottom: 40px;
                    color: #161718;letter-spacing: 0.6px;
                }
                ul {
                    li {
                        font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-style: normal;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 36px;
                        color: #161718;letter-spacing: 0.6px;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 100px;
                        }
                    }
                }
                .d_m_3_introduce {
                    font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    color: #666;letter-spacing: 0.6px;
                }
                .d_m_3_lunbo_wrap {
                    width: 1280px;
                    overflow: hidden;
                    position: relative;
                    ul.d_m_3_lunbo_box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: .5s ease-in-out;
                        .d_m_3_lunbo_item {
                            width: 1280px;
                            float: left;
                            .d_m_3_lunbo_item_title {
                                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 36px;
                                color: #161718;
                                border-bottom: 2px solid #161718;
                                padding-bottom: 10px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                    ul.d_m_3_lunbo_btn_wrap {
                        width: fit-content;
                        margin: 0 auto;
                        position: absolute;
                        display: flex;
                        bottom: 40px;
                        left: 50%;
                        transform: translateX(-50%);
                        li {
                            width: 160px;
                            float: left;
                            margin: 0 5px;
                            color: #d9d9d9;
                            text-align: center;
                            font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-style: italic;
                            font-size: 18px;
                            line-height: 24px;
                            user-select: none;
                            cursor: pointer;
                            &.d_m_3_lunbo_btn_wrap_item_active {
                                color: #333;
                                .d_m_3_lunbo_btn_wrap_item_border {
                                    background: #333;
                                }
                            }
                            .d_m_3_lunbo_btn_wrap_item_border {
                                height: 4px;
                                border-radius: 2px;
                                background-color: #d9d9d9;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .research_header {
        height: 500px;
        background: url('../img/gackground/6.jpg') no-repeat center / cover;
        position: relative;
        .r_h_b {
            position: absolute;
            left: 50%;
            bottom: 188px;
            transform: translateX(-50%);
            text-align: center;
            color: #fff;
            white-space: nowrap;
            .r_h_t_b {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 64px;
                font-weight: 700;
                line-height: 78px;
                text-align: center;   
                margin-bottom: 26px;  letter-spacing: 0.6px;       
            }
            .r_h_t_s{
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                text-align: center;
                text-transform: capitalize;
            }
        }
    }
    .research_contain {
        width: 1280px;
        margin: 0 auto;
        color: #fff;
        .r_c_model_1 {
            .r_c_model_title_1 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 50px;
                font-weight: 700;
                line-height: 72px;
                margin-bottom: 48px;letter-spacing: 0.6px;
            }
            .r_c_model_title_2 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 36px;
                color: rgba(153, 153, 153, 1);
                padding-bottom: 44px;
                border-bottom: 1px dashed rgba(51, 51, 51, 1);
                margin-bottom: 60px;letter-spacing: 0.6px;
            }  
            .r_c_model_content {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 36px;letter-spacing: 0.6px;
                &.r_c_model_content_1 {
                    margin-bottom: 40px;
                }
            } 
        }
        .r_c_model_2 {
            .r_c_model_2_title {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 34px;
                font-weight: 700;
                line-height: 36px;
                margin-bottom: 44px;
                text-transform: capitalize;letter-spacing: 0.6px;
            }
            .r_c_model_2_content {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 36px;letter-spacing: 0.6px;
            }
            .r_c_model_2_content_box {
                width: calc(100% - 62px);
                min-height: 558px;
                border-radius: 18px;
                background: rgba(34, 34, 34, 1);
                border: 1px dashed rgba(153, 153, 153, 1);
                padding: 20px 30px;
                p {
                    font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 36px;
                    padding-bottom: 14px;
                    border-bottom: 1px dashed rgba(102, 102, 102, 1);
                }
                .r_c_model_2_content_info {
                    padding: 25px 0 0 0;
                    .r_c_model_2_content_info_item {
                        font-family: Courier New;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;

                    }
                    .r_c_m_2_c_u {
                        li {
                            div {
                                margin-right: 20px;
                                &.r_c_m_2_c_l_i_1 {
                                    width: 30px;
                                    text-align: center;
                                }
                                &.r_c_m_2_c_l_i_2 {
                                    width: 200px;
                                }
                                &.r_c_m_2_c_l_i_3 {
                                    width: 150px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .r_c_model_3 {
            .r_c_model_3_box {
                ul {
                    flex-wrap: wrap;
                    li {
                        width: calc((100% - 80px) / 3 - 60px);
                        margin: 0 40px 50px 0;
                        padding: 25px 30px;
                        background: rgba(34, 34, 34, 1);
                        height: 200px;
                        border-radius: 18px;
                        
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                        .r_c_model_3_box_item_title {
                            margin-bottom: 3px;
                            height: 27px;
                            span {
                                text-transform: capitalize;
                                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 7px;
                                &.r_c_model_3_box_item_s {
                                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .r_c_model_3_box_item_time {
                            font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 26px;
                            margin-bottom: 19px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 26px;
                            white-space: nowrap;
                        }
                        .r_c_model_3_chart_box {
                            padding-bottom: 12px;
                            border-bottom: 1px dashed rgba(102, 102, 102, 1);
                        }
                    }
                }
                .r_c_model_3_showMore {
                    width: 400px;
                    height: 50px;
                    border-radius: 6px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    margin: 0 auto;
                    line-height: 50px;
                    text-align: center;
                    user-select: none;
                    cursor: pointer;
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: capitalize;letter-spacing: 0.6px;
                }
            }   
        }
        .r_c_model_4 {
            ul.r_c_model_4_box {
                display: flex;
                li {
                    user-select: none;
                    cursor: pointer;
                    margin-right: 40px;
                    flex: 1;
                    height: 420px;
                    padding: 20px 30px;
                    border-radius: 18px;
                    background: rgba(34, 34, 34, 1);
                    border: 1px dashed rgba(102, 102, 102, 1);
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    .r_c_model_4_ul_time {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 36px;
                    }
                    .r_c_model_4_ul_title {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 18px;
                        padding-bottom: 16px;
                        border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                        margin-bottom: 15px;
                    }
                    .r_c_model_4_li_num {
                        min-width: 70px;
                        text-align: left;
                        font-weight: 700;
                    }
                    .r_c_model_4_li_list {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    &.r_c_model_4_li_active {
                        background: rgba(124, 58, 237, 1);
                        border: 1px solid rgba(124, 58, 237, 1);
                    }
                }
            }
            .r_c_model_4_title2 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                margin-bottom: 34px;
                text-transform: capitalize;letter-spacing: 0.6px;
            }
            ul.r_c_model_4_data {
                li {
                    &.r_c_model_4_data_item {
                        min-height: 120px;
                        border-radius: 18px;
                        margin-bottom: 30px;
                        background: rgba(34, 34, 34, 1);
                        padding: 20px 30px;
                        .r_c_model_4_data_item_title {
                            font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            margin-bottom: 6px;
                        }
                        .r_c_model_4_data_item_content {
                            font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            color: rgba(170, 170, 170, 1);

                        }
                    }
                    &.r_c_model_4_data_loading {
                        height: 96px;
                        text-align: center;
                        img {
                            width: 36px;
                            height: 36px;
                            margin: 0 auto 30px;
                        }
                    }
                }
            }
        }
    }
}
.w-1280 {
    width: 1280px;
}
.b-b-2 {
    border-bottom: 2px solid #161718;
}
.pb-10 {
    padding-bottom: 10px;
}
.o-x-s {
    overflow-x: scroll;
}
.pt-40 {
    padding-top: 40px;
}
.mt-40 {
    margin-bottom: 40px;
}
.h-60 {
    height: 60px;
}
@media screen and (max-width: 1279px) {
    .model-banner-box {
        width: 100%;
        height: 330px;
        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        color: #fff;
        position: relative;
    }
    .mode-qualitySequence-wrap {
        color: #fff;
        .qualitySequence-banner-img{
            background: url('../img/gackground/4.jpg') no-repeat center / cover;
            .qualitySequence_box_wrapper {
                height: calc(100% - 60px);
                position: relative;
            }
            .qualitySequence-banner-title {
                width: calc(100% - 80px);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-transform: capitalize;
            }
        }
        
        .prodClusters-banner-img{
            background: url('../img/gackground/7.jpg') no-repeat center / cover;
            .prodClusters-banner-title {
                width: calc(100% - 80px);
                white-space: nowrap;
                position: absolute;
                bottom: 98px;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                text-transform: capitalize;
                li {
                    text-align: left;
                    &:first-child {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 30px;
                        font-weight: 700;
                        line-height: 36px;
                        white-space: break-spaces;
                        // text-wrap: wrap;
                        // width: 300px;
                        // word-break: break-all;
                        // word-wrap: break-word;
                        // overflow: hidden;
                    }
                }
            }
        }
        .content-first-model {
            .q_c_f_w_b {
                width: calc(100% - 60px);
                min-height: 332px;
                border-radius: 20px;
                background: rgba(34, 34, 34, 1);
                padding: 22px 30px;
                color: #fff;
                .q_c_f_w_b-title {
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 19px;
                    font-weight: 700;
                    line-height: 36px;
                    margin-bottom: 15px;
                    text-transform: capitalize;
                }
                .diver {
                    margin-bottom: 15px;
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    text-align: left;
                    align-items: center;
                    text-transform: capitalize;
                    span {
                        color: rgba(47, 239, 119, 1);
                    }
                    > div {
                        margin-left: 22px;
                        border-top: 1px dashed rgba(51, 51, 51, 1)
                    }
                }
                
                .q_c_f_w_b-list {
                    flex: 1;
                    margin-bottom: 24px;
                    li {
                        width: 100%;
                        height: 31px;
                        line-height: 31px;
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 15px;
                        font-weight: 400;
                        margin-bottom: 10px;
                        span {
                            font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 30px;
                            font-weight: 700;
                            color: rgba(47, 239, 119, 1);
                            margin-right: 6px;
                            position: relative;
                            top: 4px;
                        }
                    }
                }
                .q_c_f_w_b-button {
                    width: 100%;
                    height: 68px;
                    border-radius: 6px;
                    background: rgba(124, 58, 237, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    line-height: 30px;
                    user-select: none;
                    cursor: pointer;
                    text-transform: capitalize;
                    .q_c_f_w_b-button-big {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size:18px;
                        font-weight: 700;
                        line-height: 26px;
                        text-align: center;
                    }
                    .q_c_f_w_b-button-small {
                        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: .64rem;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.05em;
                    }
                }
            }
        }
        .model-content-box {
            width: calc(100% - 70px);
            margin: 0 auto;
        }
        .batch-model-box {
            padding-top: 40px;
            padding-bottom: 60px;
            border-bottom: 1px dashed rgba(51, 51, 51, 1);
            .model-content-title {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                margin-bottom: 20px;
                text-transform: capitalize;
            }
            .model-content-img-left {
                height: 300px;
                border-radius: 20px;
                overflow: hidden;
                margin-bottom: 20px;
            }
            
            .model-content-content-article {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 20px;
            }
            .model-content-content-button {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 36px;
                text-decoration: underline;
                user-select: none;
                cursor: pointer;
            }
        }
        
        .qualitySequence-bottom-slideShow {
            padding-top: 40px;
            font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            color: #fff;
            p {
                margin-bottom: 40px;
            }
        }
        .model-foot-content {
            width: 100%;
            height: 200px;
            margin-bottom: 108px;
            border-radius: 20px;
            .slick-slider {
                position: relative;
                .slick-dots-bottom {
                    width: 100%;
                    position: absolute;
                    bottom: -40px;
                }
            }
            .slick-list {
                border-radius: 20px;
            }
            .model-foot-content-box {
                width: 100%;
                height: 170px;
            }
        }
    }
    .sf-custom-footer-wrap {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #060708;
        background-color: #eee;
        > div {
            text-align: center;
            .sf-custom-footer-describute {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 36px;
                text-align: center;
                padding-top: 20px;
            }
        }
    }
    .sf-detail-header {
        color: #fff;
        height: 430px;
        background: url('../img/gackground/11.jpg') no-repeat center / cover;
        position: relative;
        padding-top: 60px;
        .d_h_c_b_wrap {
            height: 100%;
            position: relative;
        }
        .d_h_c_b {
            width: calc(100% - 70px);
            position: absolute;
            // bottom: 56px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .d_h_c_b_l_title {
                margin-bottom: 14px;
                p {
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 31px;
                    font-weight: 700;
                    line-height: 44px;
                }
            }
            .d_h_c_b_l_content {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
    .pt-30 {
        padding-top: 30px;
    }
    .sf-detail-content {
        width: calc(100% - 70px);
        padding: 0 35px;
        margin: 0 auto 40px;
        .sf-detail-content-box {
            .d_c_t_1 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: rgba(22, 23, 24, 1);
                text-transform: capitalize;
                text-transform: capitalize;
            }
            .d_c_t_s {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.1px;
                text-align: left;
                color: rgba(102, 102, 102, 1);

            }
            .d_c_i {
                font-family: "Times New Roman";
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 26px;
                
            }
            .d_m_3 {
                .d_m_3_introduce {
                    font-family: "Times New Roman";
                    font-size: 13px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666;
                    padding-top: 20px;
                }
                .d_m_3_title {
                    font-family: "Times New Roman";
                    font-size: 15px;
                    font-weight: bold;
                    margin-bottom: 30px;
                }
                li {
                    font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    text-align: left;
                    color: rgba(22, 23, 24, 1);
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 30px;
                    }
                }
                .d_m_3_lunbo_wrap {
                    position: relative;
                    overflow: hidden;
                    .d_m_3_lunbo_box {
                        display: flex;
                        position: absolute;
                        transition: .3s ease-in-out;
                        .d_m_3_lunbo_item_title {
                            width: 100%;
                            white-space: nowrap;
                            overflow-y: scroll;
                        }
                        li {
                            // width: 315px;
                            overflow-x: scroll;
                            float: left;
                            margin-right: 10px;
                            .d_m_3_lunbo_item_title {
                                font-weight: bold;
                                border-bottom: 1px solid #000;
                            }
                        }
                    }
                    .d_m_3_lunbo_btn_wrap {
                        // position: absolute;
                        // bottom: 0;
                        display: flex;
                        width: 100%;
                        li {
                            flex: 1;
                            text-align: center;
                            padding: 0 5px;
                            color: #d9d9d9;
                            font-style: italic;
                            .d_m_3_lunbo_btn_wrap_item_border {
                                height: 2px;
                                border-radius: 2px;
                                 background: #d9d9d9;
                                 font-size: 13px;
                            }
                            &.d_m_3_lunbo_btn_wrap_item_active {
                                color: #000;
                                .d_m_3_lunbo_btn_wrap_item_border{
                                    background: #000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .research_header {
        color: #fff;
        height: 330px;
        background: url('../img/gackground/6.jpg') no-repeat center / cover;
        position: relative;
        .r_h_b {
            width: calc(100% - 70px);
            position: absolute;
            left: 50%;
            bottom: 108px;
            transform: translateX(-50%);
            text-align: left;
            .r_h_t_b {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 36px;
                font-weight: 700;
                line-height: 44px;     
                margin-bottom: 6px;   
            }
            .r_h_t_s{
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 13px;
                font-weight: 700;
                line-height: 20px;
            }
        }
    }
    .research_contain {
        color: #fff;
        width: calc(100% - 70px);
        margin: 0 auto;
        .r_c_model_1 {
            .r_c_model_title_1 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 20px;
            }
            .r_c_model_title_2 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                color: rgba(153, 153, 153, 1);
                padding-bottom: 20px;
                border-bottom: 1px dashed rgba(51, 51, 51, 1);
                margin-bottom: 20px;
            }  
            .r_c_model_content {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: .86rem;
                font-weight: 400;
                line-height: 26px;
                &.r_c_model_content_1 {
                    margin-bottom: 20px;
                }
            } 
        }
        .r_c_model_2 {
            .r_c_model_2_title {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 15px;
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 20px;
                text-transform: capitalize;
            }
            .r_c_model_2_content {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: .86rem;
                font-weight: 400;
                line-height: 26px;
            }
            .r_c_model_2_content_box {
                width: calc(100% - 32px);
                min-height: 518px;
                border-radius: 18px;
                background: rgba(34, 34, 34, 1);
                border: 1px dashed rgba(153, 153, 153, 1);
                padding: 10px 15px;
                p {
                    font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 36px;
                    padding-bottom: 14px;
                    border-bottom: 1px dashed rgba(102, 102, 102, 1);
                }
                .r_c_model_2_content_info {
                    padding: 25px 0 0 0;
                    .r_c_model_2_content_info_item {
                        font-family: Courier New;
                        font-size: .725rem;
                        font-weight: 400;
                        line-height: 22px;

                    }
                    .r_c_m_2_c_u {
                        li {
                            div {
                                white-space: nowrap;
                                // margin-right: 5px;
                                &.r_c_m_2_c_l_i_1 {
                                    width: 20px;
                                    text-align: center;
                                }
                                &.r_c_m_2_c_l_i_2 {
                                    width: 150px !important;
                                    
                                }
                                &.r_c_m_2_c_l_i_3 {
                                    // width: 80px;
                                    flex: 1;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .r_c_model_3 {
            .r_c_model_3_box {
                ul {
                    flex-wrap: wrap;
                    li {
                        // width: calc((100% - 80px) / 3 - 60px);
                        margin: 0 0 30px;
                        padding: 25px 30px;
                        background: rgba(34, 34, 34, 1);
                        min-height: 200px;
                        border-radius: 18px;
                        
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                        .r_c_model_3_box_item_title {
                            margin-bottom: 3px;
                            height: 27px;
                            span {

                                text-transform: capitalize;
                                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 22px;
                                &.r_c_model_3_box_item_s {
                                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: rgba(153, 153, 153, 1);

                                }
                            }
                        }
                        .r_c_model_3_box_item_time {
                            font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 26px;
                            margin-bottom: 19px;
                        }
                        .r_c_model_3_chart_box {
                            padding-bottom: 12px;
                            border-bottom: 1px dashed rgba(102, 102, 102, 1);
                        }
                    }
                }
                .r_c_model_3_showMore {
                    // width: 400px;
                    height: 40px;
                    border-radius: 6px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    margin: 0 auto;
                    line-height: 40px;
                    text-align: center;
                    user-select: none;
                    cursor: pointer;
                    font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                }
            }   
        }
        .r_c_model_4 {
            ul.r_c_model_4_box {
                width: calc(100% + 40px);
                overflow-x: scroll;
                display: flex;
                margin: 0 -35px 40px;
                padding-left: 30px;
                li {
                    width: 300px;
                    user-select: none;
                    cursor: pointer;
                    margin-right: 20px;
                    flex: 1;
                    height: 420px;
                    padding: 20px 30px;
                    border-radius: 18px;
                    background: rgba(34, 34, 34, 1);
                    white-space: nowrap;
                    border: 1px dashed rgba(102, 102, 102, 1);
                    // &:nth-child(3n) {
                    //     margin-right: 0;
                    // }
                    .r_c_model_4_ul_time {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 36px;
                    }
                    .r_c_model_4_ul_title {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 18px;
                        padding-bottom: 16px;
                        border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                        margin-bottom: 15px;
                    }
                    .r_c_model_4_li_num {
                        min-width: 70px;
                        text-align: right;
                        font-weight: 700;
                    }
                    .r_c_model_4_li_list {
                        font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    &.r_c_model_4_li_active {
                        background: rgba(124, 58, 237, 1);
                        border: 1px solid rgba(124, 58, 237, 1);
                    }
                }
            }
            .r_c_model_4_title2 {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 15px;
                font-weight: 400;
                line-height: 36px;
                margin-bottom: 34px;
                text-transform: capitalize;
            }
            ul.r_c_model_4_data {
                li {
                    &.r_c_model_4_data_item {
                        min-height: 120px;
                        border-radius: 18px;
                        margin-bottom: 30px;
                        background: rgba(34, 34, 34, 1);
                        padding: 20px 30px;
                        .r_c_model_4_data_item_title {
                            font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 24px;
                            margin-bottom: 6px;
                            word-break: break-all;
                            white-space: wrap;
                        }
                        .r_c_model_4_data_item_content {
                            font-family: 'Courier New', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                            font-size: .625rem;
                            font-weight: 400;
                            line-height: 18px;
                            color: rgba(170, 170, 170, 1);
                            word-break: break-all;
                            white-space: wrap;

                        }
                    }
                    &.r_c_model_4_data_loading {
                        height: 96px;
                        text-align: center;
                        img {
                            width: 36px;
                            height: 36px;
                            margin: 0 auto 30px;
                        }
                    }
                }
            }
        }
    }
}
.w-300 {
    width: 300px;
}
.mb-30 {
    margin-bottom: 30px;
}
.lh-0 {
    line-height: 0;
}
.b-b-1 {
    border-bottom: 1px solid #161718;
}
