
@font-face {
    font-family: "Times New Roman";
    src: url('../font-icon/Times_New_Roman/times.ttf')
         url('../font-icon/Times_New_Roman/timesbd.ttf')
         url('../font-icon/Times_New_Roman/timesbi.ttf')
         url('../font-icon/Times_New_Roman/timesi.ttf');
}
@font-face {
    font-family: "Inter";
    src: url('../font-icon/Inter/Inter-Black.ttf')
         url('../font-icon/Inter/Inter-Bold.ttf')
         url('../font-icon/Inter//Inter-ExtraBold.ttf')
         url('../font-icon/Inter/Inter-ExtraLight.ttf')
         url('../font-icon/Inter/Inter-Light.ttf')
         url('../font-icon/Inter/Inter-Medium.ttf')
         url('../font-icon/Inter/Inter-Regular.ttf')
         url('../font-icon/Inter/Inter-SemiBold.ttf')
         url('../font-icon/Inter/Inter-Thin.ttf');
}
@font-face {
    font-family: "Courier New";
    src: url('../font-icon/Courier_New/cour.ttf')
         url('../font-icon/Courier_New/courbd.ttf')
         url('../font-icon/Courier_New/courbi.ttf')
         url('../font-icon/Courier_New/couri.ttf');
}
@media screen and (min-width: 1280px) {
    .sf-footer-wrap {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(6,7,8,.3);
        > div {
            text-align: center;
            .sf-footer-describute {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 36px;
                text-align: center;
                padding-top: 24px;
            }
        }
    }
}
@media screen and (max-width: 1279px) {
    .sf-footer-wrap {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(6,7,8,.3);
        > div {
            text-align: center;
            .sf-footer-describute {
                font-family: 'Times New Roman', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 36px;
                text-align: center;
                padding-top: 20px;
            }
        }
    }
}