
@font-face {
    font-family: "Times New Roman";
    src: url('../font-icon/Times_New_Roman/times.ttf')
         url('../font-icon/Times_New_Roman/timesbd.ttf')
         url('../font-icon/Times_New_Roman/timesbi.ttf')
         url('../font-icon/Times_New_Roman/timesi.ttf');
}
@font-face {
    font-family: "Inter";
    src: url('../font-icon/Inter/Inter-Black.ttf')
         url('../font-icon/Inter/Inter-Bold.ttf')
         url('../font-icon/Inter//Inter-ExtraBold.ttf')
         url('../font-icon/Inter/Inter-ExtraLight.ttf')
         url('../font-icon/Inter/Inter-Light.ttf')
         url('../font-icon/Inter/Inter-Medium.ttf')
         url('../font-icon/Inter/Inter-Regular.ttf')
         url('../font-icon/Inter/Inter-SemiBold.ttf')
         url('../font-icon/Inter/Inter-Thin.ttf');
}
@font-face {
    font-family: "Courier New";
    src: url('../font-icon/Courier_New/cour.ttf')
         url('../font-icon/Courier_New/courbd.ttf')
         url('../font-icon/Courier_New/courbi.ttf')
         url('../font-icon/Courier_New/couri.ttf');
}
@media screen and (min-width: 1280px) {
    
    .function-model-page {
        padding: 300px 0 196px;
        color: #fff;
        background: #161718;
        p {
            width: fit-content;
            text-align: center;
            margin: 0 auto;
            &.function-model-title {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 96px;
                font-style: italic;
                font-weight: 700;
                line-height: 117px;
                color: rgba(255, 255, 255, .2);
                margin-bottom: 144px;
            }
            &.function-model-describute {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 37px;
                margin-bottom: 24px;
                text-transform: capitalize;letter-spacing: 0.6px;
            }
            &.function-model-back {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 20px;
                font-weight: 400;
                line-height: 25px;
                color: #A78BFA;
                cursor: pointer;
                user-select: none;letter-spacing: 0.6px;
            }
        }
    }
}
@media screen and (max-width: 1279px) {
    
    .function-model-page {
        padding: 140px 0 116px;
        color: #fff;
        background: #161718;
        height: calc(100vh - 476px);
        p {
            width: fit-content;
            text-align: center;
            margin: 0 auto;
            &.function-model-title {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 36px;
                font-style: italic;
                font-weight: 700;
                line-height: 44px;
                color: rgba(255, 255, 255, .2);
                margin-bottom: 144px;
            }
            &.function-model-describute {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 38px;
                text-transform: capitalize;
            }
            &.function-model-back {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: #A78BFA;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}