
@font-face {
    font-family: "Times New Roman";
    src: url('../font-icon/Times_New_Roman/times.ttf')
         url('../font-icon/Times_New_Roman/timesbd.ttf')
         url('../font-icon/Times_New_Roman/timesbi.ttf')
         url('../font-icon/Times_New_Roman/timesi.ttf');
}
@font-face {
    font-family: "Inter";
    src: url('../font-icon/Inter/Inter-Black.ttf')
         url('../font-icon/Inter/Inter-Bold.ttf')
         url('../font-icon/Inter//Inter-ExtraBold.ttf')
         url('../font-icon/Inter/Inter-ExtraLight.ttf')
         url('../font-icon/Inter/Inter-Light.ttf')
         url('../font-icon/Inter/Inter-Medium.ttf')
         url('../font-icon/Inter/Inter-Regular.ttf')
         url('../font-icon/Inter/Inter-SemiBold.ttf')
         url('../font-icon/Inter/Inter-Thin.ttf');
}
@font-face {
    font-family: "Courier New";
    src: url('../font-icon/Courier_New/cour.ttf')
         url('../font-icon/Courier_New/courbd.ttf')
         url('../font-icon/Courier_New/courbi.ttf')
         url('../font-icon/Courier_New/couri.ttf');
}

.public-header-wrap {
    width: 100%;
    height: 60px;
    background: rgba(6, 7, 8, .95);
    display: flex;
    justify-content: space-between;
    color: #F6F7F8;
    align-items: center;
    position: fixed;
    z-index: 999;
    .header-logo {
        height: 30px;
        line-height: 30px;
        user-select: none;
        cursor: pointer;
        font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        z-index: 999;
        .logo_name {
            padding-left: 16px;
        }
    }
    .header-lang {
        display: flex;
        align-items: center;
        .menu {
            user-select: none;
            cursor: pointer;
            font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
            font-size: 16px;
            font-weight: 700;
            margin-right: 30px;
        }
        .change-lang-box {
            width: 52px;
            height: 18px;
            border-radius: 24px;
            background: #222;
            padding: 3px 4px;
            display: flex;
            user-select: none;
            position: relative;
            cursor: pointer;
            transition: ease-in-out .3s;
            &.header-lang-active-color {
                background-color: rgba(47, 239, 119, 0.3);
            }
            .lang-circle-green {
                width: 18px;
                height: 18px;
                background: #2fef77;
                border-radius: 50%;
                position: absolute;
                &.lang-circle-left {
                    left: 4px;
                }
                &.lang-circle-right {
                    right: 4px;
                }
            }
            .header-lang-name {
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 12px;
                font-weight: 700;
                color: #fff;
                flex: 1;
                text-align: center;
                line-height: 18px;
                &.header-lang-name-right {
                    margin-left: 16px;
                }
                &.header-lang-name-left {
                    margin-right: 16px;
                }
            }

        }
    }
}
@media screen and (min-width: 1280px) {
    .public-header-wrap {
        .header-logo {
            margin-left: 50px;
            .logo_name {
                padding-left: 8px;
            }
        }
        .header-menu {
            display: flex;
            li {
                margin: 0 46px;
                user-select: none;
                cursor: pointer;
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;letter-spacing: 0.6px;
            }
        }
        .header-lang {
            margin-right: 60px;
        }
    }
}
@media screen and (max-width: 1279px) {
    .public-header-wrap {
        position: fixed;
        z-index: 9999;
        overflow: hidden;
        .header-logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            .logo_name {
                padding-left: 8px;
            }
        }
        .header-lang {
            margin-right: 15px;
            position: relative;
            z-index: 999;
            .change-lang-box {
                width: 33px;
                height: 14px;
                .header-lang-name {
                    font-size: 10px;
                    line-height: 16px;
                }
                .lang-circle-green {
                    width: 13px;
                    height: 13px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .sf-header-bg-black {
            position: absolute;
            background: rgba(6, 7, 8, .95);
            width: 100%;
            height: 60px;
            top: 0;
            left: 0;
            z-index: 998;
        }
        .header-menu {
            width: calc(100% - 70px);
            position: fixed;
            left: 0;
            background: #000;
            height: calc(100vh - 90px);
            top: 0;
            transition: ease-in-out .5s;
            transform: translateY(-100vh);
            padding: 35px;
            z-index: 997;
            overflow: hidden;
            &.header-menu-open {
                transform: translateY(60px);
            }
            &.header-menu-close {
                bottom: 60px;
            }
            li {
                line-height: 66px;
                font-family: 'Inter', "system-ui",-apple-system,"Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans",sans-serif;
                font-size: 22px;
                font-weight: 700;
                border-bottom: 1px dashed rgba(51, 51, 51, 1);letter-spacing: 0.6px;
            }
        }
        .sf-header-menu-icon {
            width: 60px;
            padding-left: 15px;
            position: relative;
            z-index: 999;
            .sf-header-menu-icon-1 {
                width: 15px;
                height: 2.5px;
                margin-bottom: 3px;
                background-color: #fff;
                border-radius: 6px;
                transition: ease-in .5s;
                &.sf-header-menu-icon-1-open {
                    width: 22px;
                }
            }
            .sf-header-menu-icon-2 {
                width: 13px;
                height: 2.5px;
                margin-bottom: 3px;
                background-color: #fff;
                transition: ease-in .5s;
                border-radius: 6px;
                &.sf-header-menu-icon-2-open {
                    width: 19px;
                }
            }
            .sf-header-menu-icon-3 {
                width: 11px;
                height: 2.5px;
                background-color: #fff;
                border-radius: 6px;
                transition: ease-in .5s;
                &.sf-header-menu-icon-3-open {
                    width: 16px;
                }
            }
        }
    }
}